.custom-check-box {
  width: 18px;
  height: 18px;
  border: 1px solid #57a3b0;
  border-radius: 0.2rem;
  background-color: white;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-check-box-enabled {
  width: 100%;
  height: 100%;

  background-color: #57a3b0;
}
