.disable-margin {
  margin: 0 !important;
}

.disable-margin .page-link {
  color: black;
}

.disable-margin .page-link:hover {
  background-color: #e9ecef;
  color: black;
}

.disable-margin .page-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.disable-margin .page-item.active .page-link {
  color: white;
}

.cpagination-item a {
  pointer-events: none;
  /* max-width: 50px; */
  /* height: 31px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-font {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
