.nav-color {
  background-color: var(--main-bg);
}

.navbar-container {
  width: 100%;
  height: 60px;
  background-color: var(--main-bg);
  /* overflow: auto; */
  top: 0;
  left: 0;
  z-index: 999;
}

.navbar-content {
  max-width: 1320px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

@media (max-width: 1399px) {
  .navbar-content {
    max-width: 1140px;
  }
}

@media (max-width: 1198px) {
  .navbar-content {
    max-width: 960px;
  }
}

@media (min-width: 416px) and (max-width: 991px) {
  .navbar-content {
    max-width: 720px;
  }
}

@media (min-width: 416px) and (max-width: 766px) {
  .navbar-content {
    max-width: 540px;
  }
}

/* .logout-button {
  background-color: white;
  border-radius: 5px;
  color: var(--main-bg);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.logout-button:hover {
  background-color: #c6c5c5;
} */
