.button-neutral {
  background-color: var(--main-bg) !important;
  border-width: 1px !important;
  border-color: var(--main-bg) !important;
  color: white !important;
}

.button-neutral:hover {
  background-color: var(--main-bg-darker) !important;
}
