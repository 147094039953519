:root {
  --main-bg: #57a3b0;
  --main-bg-darker: #357778;
  --main-bg-lighter: #aed8da;
}

.root-container {
  display: flex;
  flex-direction: column;
  height: 94vh;
  /* justify-content: center; */
}

.custom-button {
  background-color: var(--main-bg) !important;
  border-color: var(--main-bg) !important;
}

.custom-button:hover {
  background-color: var(--main-bg-darker) !important;
  border-color: var(--main-bg-darker) !important;
}
