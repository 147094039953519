.no-glow:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-control {
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .form-control {
    font-size: 10px !important;
  }
}
