.col-pagination {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.invisible-block {
  visibility: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

@media (max-width: 768px) {
  .col-pagination {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    height: 0px;
  }
  /* 200 */
}

@media (max-width: 500px) {
  .col-pagination {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    height: 10px;
  }
}

@media (max-width: 480px) {
  .col-pagination {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    height: 10px;
  }
  /* 300 */
}

@media (max-width: 375px) {
  .col-pagination {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    height: 40px;
  }
}
