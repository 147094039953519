.selected-row {
  background-color: #6cb7bf;
}

.selected-row td {
  color: white !important;
}

td {
  background-color: transparent !important;
  cursor: pointer;
  font-size: 0.9rem !important;
}

.disabled-row {
  pointer-events: none !important;
}

.disabled-row td {
  opacity: 0.5;
}

.custom-tbody {
  height: 100%;
  width: 100%;
  max-width: 1140px;
  overflow-y: auto;
}

@media only screen and (max-width: 1060px) {
  .custom-tbody {
    height: 50%;
  }
  td {
    background-color: transparent !important;
    cursor: pointer;
    font-size: 0.6rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .custom-tbody {
    height: 50%;
  }
  td {
    background-color: transparent !important;
    cursor: pointer;
    font-size: 0.6rem !important;
  }
}

@media only screen and (max-width: 480px) {
  .custom-tbody {
    height: 30%;
  }
  td {
    background-color: transparent !important;
    cursor: pointer;
    font-size: 0.6rem !important;
  }
}

@media only screen and (max-width: 452px) {
  .custom-tbody {
    height: 30%;
  }
  td {
    background-color: transparent !important;
    cursor: pointer;
    font-size: 0.5rem !important;
  }
}
@media only screen and (max-width: 384px) {
  .custom-tbody {
    height: 30%;
  }
  td {
    background-color: transparent !important;
    cursor: pointer;
    font-size: 0.4rem !important;
  }
}
