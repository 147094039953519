html,
body {
  margin: 0;
}

.main-menu {
  color: rgb(255, 255, 255);
  background-color: #3f3f44;
  /* background: linear-gradient(to right, #143168, #3e9ef7); */
  width: 100vw;
  height: 100vh;
  display: flex;
}
.holder {
  color: rgb(255, 255, 255);
  background-color: rgb(230, 230, 230);
  /* background: linear-gradient(to right, #143168, #3e9ef7); */
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal .modal-dialog {
  width: 40%;
  height: 10%;
  margin-top: 20%;
}

.center {
  border-style: solid;
  background-color: #f6f7f8;
  border-color: #57a3b0;
  border-width: 15px;
  height: 600px;
  width: 400px;
  display: flex;
  flex-direction: column; /* вертикальное направление */
  align-items: center;
  justify-content: flex-start;
}

.text {
  font-size: 2em;
  color: #57a3b0;
}

.input-field {
  display: flex;
  flex-direction: column; /* Расположение дочерних элементов (input) вертикально */
  margin-top: 12%;
}

.form-group {
  margin: 12px;
}

.custom-input {
  background-color: #e6e6e6;
  color: rgb(105, 105, 105);
  border: none;
}

.form-control:focus {
  border-color: #57a3b0;
  box-shadow: 0 0 0 0.3rem rgba(8, 172, 164, 0.25);
}

.vertical-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
